import axios from "axios";
const dom = "https://api.mnp.mv/";
const baseDomain = dom + "api/members/"; //"http://api.mnp.mv/api/office/";

const tok = localStorage.getItem("mnp_member_token");

export const customHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${tok}`,
};

export const baseUrl = `${baseDomain}`;
export const imageUrl = `http://localhost:8000/storage/`;

export default axios.create({
  baseUrl,
  headers: customHeaders,
  imageUrl,
});

export const serializeQuery = (query) => {
  return Object.keys(query)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    )
    .join("&");
};
