<template>
  <div class="mb-2">
    <v-app-bar color="#0a225f" dark>
      <v-menu rounded="lg" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-app-bar-nav-icon v-bind="attrs" v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-list nav dense>
          <v-list-item v-for="m in menu" :key="m.id">
            <v-list-item-title style="cursor: pointer" @click="gotoLink(m)">
              {{ m.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-toolbar-title>MNP Member Portal</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu rounded="lg" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-avatar size="24" class="ml-2" v-bind="attrs" v-on="on">
            <img :src="no_img" />
          </v-avatar>
        </template>
        <v-list dense>
          <v-list-item-group v-model="selectedMenu" color="primary">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="
                    (!empty(user.name) ? user.name : user.full_name) +
                    ' (' +
                    user.id_card_no +
                    ')'
                  "
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="menu in userMenus" :key="menu.id">
              <v-list-item-content>
                <v-list-item-title v-text="menu.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  v-text="'Log Out'"
                  @click="signOut"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "MobileHeader",
  data() {
    return {};
  },
  methods: {
    gotoLink(link) {
      this.$router.push(link.slug);
    },
  },
};
</script>
<style>
.v-toolbar__extension {
  background: #3c9bf9 !important;
}
</style>
