<template>
  <v-app>
    <template v-if="!empty(user)">
      <full-header />
    </template>
    <v-main style="padding: 0 !important">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import FullHeader from "./components/header/Header.vue";
export default {
  components: {
    FullHeader,
  },
  created() {
    if (this.empty(this.user)) {
      this.$router.push("/sign-in");
    }
  },
  name: "App",
};
</script>
