import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    //component: Home,
    redirect: "/home",
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "../components/auth/SignIn"),
  },
  {
    path: "/home",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "register" */ "../components/home/index"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "register" */ "../components/profile/index"),
  },
  {
    path: "/campaigns",
    name: "Campaigns",
    component: () =>
      import(
        /* webpackChunkName: "campaigns" */ "../components/campaigns/index"
      ),
  },
  {
    path: "/my-voters",
    name: "MyVoters",
    component: () =>
      import(
        /* webpackChunkName: "my-voters" */ "../components/myvoters/index"
      ),
  },
  {
    path: "/voters",
    name: "Voters",
    component: () =>
      import(/* webpackChunkName: "voters" */ "../components/voters/index"),
  },
  {
    path: "/presidents",
    name: "ConstituencyPesidents",
    component: () =>
      import(
        /* webpackChunkName: "presidents" */ "../components/constituencypresidents/index"
      ),
  },
  {
    path: "/volunteers",
    name: "Volunteers",
    component: () =>
      import(
        /* webpackChunkName: "volunteers" */ "../components/volunteers/index"
      ),
  },
  {
    path: "/islands",
    name: "Islands",
    component: () =>
      import(/* webpackChunkName: "islands" */ "../components/islands/index"),
  },
  {
    path: "/target-voters",
    name: "TargetVoters",
    component: () =>
      import(
        /* webpackChunkName: "target-voters" */ "../components/targetvoters/index"
      ),
  },
  {
    path: "/transport-list",
    name: "TransportList",
    component: () =>
      import(
        /* webpackChunkName: "transport-list" */ "../components/transport/index"
      ),
  },
  {
    path: "/followup-list",
    name: "FollowupList",
    component: () =>
      import(
        /* webpackChunkName: "followup-list" */ "../components/followup/index"
      ),
  },
  {
    path: "/vp",
    name: "VP",
    component: () =>
      import(/* webpackChunkName: "target-voters" */ "../components/home/vp"),
  },
  {
    path: "/my-boxes",
    name: "MyBoxes",
    component: () =>
      import(/* webpackChunkName: "my-boxes" */ "../components/voting/index"),
  },
  {
    path: "/update-votes",
    name: "UpdateVotes",
    component: () =>
      import(
        /* webpackChunkName: "my-voters" */ "../components/voting/updateVotes"
      ),
  },
  {
    path: "/zero-dash-board",
    name: "UpdateVoteZeroDayDashboard",
    component: () =>
      import(
        /* webpackChunkName: "my-voters" */ "../components/voting/dashboard"
      ),
  },
  {
    path: "/not-voted-list",
    name: "NotVotedList",
    component: () =>
      import(
        /* webpackChunkName: "my-voters" */ "../components/voting/notVoted"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
